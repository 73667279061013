import { FinalProject, TawarasshuProject, TeamProject } from './Project';

const Projects = () => {
  return (
    <div
      id="projects"
      className="p-10 container mx-auto flex flex-1 flex-col gap-4"
    >
      <h1 className="text-2xl md:text-7xl  text-center">OLDER PROJECTS</h1>
      <FinalProject />
      <TeamProject />
      <TawarasshuProject />
    </div>
  );
};

export default Projects;
