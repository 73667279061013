import ImageGallery from 'react-image-gallery';

const finalProjectTeamMebers = [
  {
    name: 'Steven Ruan',
    role: 'Level Designer',
    linkedinLink: 'https://www.linkedin.com/in/sruan96/',
    portfolioLink: 'https://www.linkedin.com/in/sruan96/',
  },
  {
    name: 'Jax Mendonca',
    role: 'Artist',
    linkedinLink: 'https://www.linkedin.com/in/jax-mendonca-718943246/',
    portfolioLink: 'https://jaxmendonca7.wixsite.com/jaxmannymendonca',
  },
  {
    name: 'Andre Dupuis',
    role: 'Programmer',
    linkedinLink: 'https://www.linkedin.com/in/andre--dupuis/',
    portfolioLink: 'https://andredups26.wixsite.com/andre-dupuis',
  },
];

const teamProjectTeamMebers = [
  {
    name: 'Jackson Blanchard',
    role: 'Artist',
    linkedinLink: 'https://www.linkedin.com/in/jacksonblanchard/',
  },
  {
    name: 'Eric Boucher',
    role: 'Level Designer',
    linkedinLink: 'https://www.linkedin.com/in/eboucherb/',
  },
];

const tawarasshuTeamMebers = [
  {
    name: 'Jax Mendonca',
    linkedinLink: 'https://www.linkedin.com/in/jacksonblanchard/',
  },
  {
    name: 'Eric Boucher',
    linkedinLink: 'https://www.linkedin.com/in/eboucherb/',
  },
];

const Project = ({
  title,
  tags,
  trailerLink,
  description,
  teamMembers,
  steamLink,
}) => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center md:py-10">
      <span className="bg-gradient-to-br from-teal-500 to-fuchsia-500 rounded-2xl p-0.5 md:mx-8 shadow-md">
        <span className="grid grid-cols-1 xl:grid-cols-2 rounded-2xl bg-zinc-50 p-4">
          <span className="m-4 flex flex-1 flex-col">
            <h1 className="text-xl md:text-4xl">{title.toUpperCase()}</h1>
            {steamLink ? (
              <div className="py-3  hover:animate-pulse">
                <a
                  href="https://store.steampowered.com/app/2449030/Plastic_Tactics_Online/"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-gradient-to-br from-teal-500 to-fuchsia-500 p-0.5"
                >
                  <span className="bg-neutral-100 px-3">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-fuchsia-500">
                      CHECK IT OUT ON STEAM
                      <i className="pl-2 fa-brands fa-steam bg-gradient-to-br from-teal-500 to-fuchsia-500 bg-clip-text text-transparent"></i>
                    </span>
                  </span>
                </a>
              </div>
            ) : null}
            <span className="grid grid-cols-1 lg:grid-cols-3 items-end pb-3">
              <div className="flex flex-row md:col-span-2 gap-2">
                {tags.map((tag) => (
                  <Tag key={tag} text={tag} />
                ))}
              </div>
            </span>
            <p className="text-md md:text-xl pb-2">{description}</p>
            <span className="flex gap-3 justify-center xl:justify-start text-center items-center">
              <h3 className="text-sm text-gray-700 italic">Collaborators</h3>
              {teamMembers.map((teamMember) => (
                <TeamMember key={teamMember.name} {...teamMember} />
              ))}
            </span>
          </span>
          <span className="flex flex-1 items-center justify-center xl:p-8">
            <iframe
              title={`${title} Trailer`}
              className="aspect-video bg-teal-500 border-dotted rounded-md p-0.5 w-full max-w-md h-full"
              allowFullScreen={true}
              src={trailerLink}
            ></iframe>
          </span>
        </span>
      </span>
    </div>
  );
};

const TeamMember = ({ name, role, linkedinLink }) => {
  return (
    <a
      href={linkedinLink}
      target="_blank"
      className="group flex flex-col p-1 px-3 bg-slate-200 hover:bg-gradient-to-tr from-teal-100 to-fuchsia-100 rounded-md transition-all items-center justify-center text-center"
      rel="noreferrer"
    >
      <span className="group-hover:opacity-0 transition-all">
        <p className="text-sm">{name}</p>
      </span>
      <span className="absolute group-hover:opacity-100 opacity-0 text-md transition-all flex flex-row gap-1 items-center">
        <p className="text-xs">{role}</p>
        <i className="text-sm fa-brands fa-linkedin"></i>
      </span>
    </a>
  );
};
const Tag = ({ text }) => {
  return (
    <span className="flex justify-center items-center bg-gray-200 rounded-full px-3 text-center text-gray-700 text-sm">
      <p>{text}</p>
    </span>
  );
};
const FinalProject = () => (
  <Project
    title="Plastic Tactics Online"
    tags={['Multiplayer', 'Strategy', 'Turn based']}
    trailerLink="https://www.youtube.com/embed/rPAKuTWiDZU"
    description="An online 3rd person shooter for up to 4 players in a turn based strategy game of wits, skills and silly weapons. Teams of Toy Soldiers take turns collecting weapons and shooting each other in an explosive battle to the last squad standing."
    teamMembers={finalProjectTeamMebers}
    steamLink={
      'https://store.steampowered.com/app/2449030/Plastic_Tactics_Online/'
    }
  />
);
const TeamProject = () => (
  <Project
    title="Spitter"
    tags={['Infinite Runner', 'Action', 'Fast Paced']}
    trailerLink="https://www.youtube.com/embed/7D10b6UgQuk"
    description="You play as the infamous bully Lara, dodge obstacles to escape as long as possible before the school principal catches you, and shoot at targets with your spit stick to score extra points."
    teamMembers={teamProjectTeamMebers}
  />
);

const TawarasshuProject = () => (
  <ProjectWithGallery
    title="Tawarasshu"
    tags={['Board Game', 'Samurai vs Ninja', 'Strategy']}
    description="At Vanocuver Film School, one of the first approaches to a real implementation of our game design is the development of a full board game. In Tawarasshu (Tower Rush), two players - the Ninja and the Samurai - take turns 
    strategically placing and moving soldiers on the map, while trying to bring as many at the Tower, located at the center. The game also features an economy aspect with Gold and Chackra, which are used to regulate pawn spawning and card usage accordingly.
    I'm proud to say that I was awarded Best Board Game for this project."
    teamMembers={tawarasshuTeamMebers}
  />
);

const images = [
  {
    original: '/tawarasshu/IMG_3326.jpeg',
    originalAlt: 'Complete game',
  },
  {
    original: '/tawarasshu/IMG_3279.jpeg',
    originalAlt: 'Game state while testing',
  },
  {
    original: '/tawarasshu/rulebook-image.png',
    originalAlt: 'Rule Book cover',
  },
  {
    original: '/tawarasshu/IMG_3319.jpeg',
  },
];

const ProjectWithGallery = ({ title, tags, description, teamMembers }) => {
  return (
    <div className="container mx-auto flex flex-col justify-center items-center md:py-10">
      <span className="bg-gradient-to-br from-teal-500 to-fuchsia-500 rounded-2xl p-0.5 md:mx-8 shadow-md">
        <span className="grid grid-cols-1 xl:grid-cols-2 rounded-2xl bg-zinc-50 p-4">
          <span className="m-4 flex flex-1 flex-col">
            <h1 className="text-xl md:text-4xl pb-3">{title.toUpperCase()}</h1>
            <span className="grid grid-cols-1 lg:grid-cols-3 items-end pb-3">
              <div className="flex flex-row md:col-span-2 gap-2">
                {tags.map((tag) => (
                  <Tag key={tag} text={tag} />
                ))}
              </div>
            </span>
            <p className="text-md md:text-xl pb-2">{description}</p>
            <span className="flex flex-1 gap-3 justify-center xl:justify-start text-center">
              <h3 className="text-sm text-gray-700 italic">Collaborators</h3>
              {teamMembers.map((teamMember) => (
                <TeamMember key={teamMember.name} {...teamMember} />
              ))}
            </span>
          </span>
          <span className="flex flex-1 items-center justify-center xl:p-8">
            <ImageGallery
              items={images}
              showThumbnails={false}
              showPlayButton={false}
              additionalClass="p-2 rounded-md bg-teal-900 bg-opacity-50"
            />
          </span>
        </span>
      </span>
    </div>
  );
};

export { FinalProject, TeamProject, TawarasshuProject };
