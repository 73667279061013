const Home = () => {
  return (
    <div className="relative flex flex-1 bg-gradient-to-br from-teal-500 to-fuchsia-500 shadow-md shadow-fuchsia-200">
      {[...locations, ...locations].map((element, index) => {
        return (
          <SkillIcon
            key={index}
            location={element}
            class="item-rotation"
            animationDelay={-(24 / (locations.length * 2)) * index + 's'}
          />
        );
      })}
      <div className="title z-10 container mx-auto flex flex-1 flex-col justify-center items-center text-center px-2">
        <h1 className="text-4xl md:text-7xl text-neutral-900">
          NEVIO VALSANGIACOMO
        </h1>
        <h2 className="text-3xl md:text-4xl pt-2 text-neutral-900 text-opacity-90">
          GAME PROGRAMMER
        </h2>
      </div>
      <a
        href="#projects"
        className=" z-20 absolute bottom-8 right-1/2 flex arrow hover:border-teal-500"
      >
        <span />
      </a>
    </div>
  );
};

const locations = [
  'images/react-icon.png',
  'images/ue-logo.png',
  'images/c-sharp-logo.png',
  'images/Unity_Technologies_logo.svg.png',
  'images/icons8-javascript-48.png',
  'images/ts-logo-512.png',
  'images/jira-logo-gradient-blue-attribution_rgb@2x.png',
];

const SkillIcon = ({ location, animationDelay, ...props }) => {
  return (
    <img
      style={{ animationDelay: animationDelay }}
      className="item-rotation h-12 rounded-md p-2 bg-opacity-50 bg-slate-200 max-w-10"
      src={location}
      alt={location}
    />
  );
};

export default Home;
