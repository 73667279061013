import video from '../video/game-engine-480.mp4';

const GameEngine = () => {
  return (
    <div
      id="game-engine"
      className="p-10 container mx-auto flex flex-1 flex-col gap-4"
    >
      <h1 className="text-2xl md:text-7xl text-center">
        MAKING A GAME WITHOUT AN ENGINE
      </h1>
      <p>
        I have always been fascinated by the technology behind our beloved game
        engines, so I finally decided to delve into their intricacies by
        building a game without using one.
      </p>
      <p>
        Here's a rundown of my experience learning about game engine
        programming. First of all what I needed was a game idea so that I could
        build engine features that are purposeful. As an avid Dota player I
        decided to focus on building an engine for making a Real Time
        Strategy(RTS) game, therefore the biggest features I want to be able to
        include are:
      </p>
      <ul class="list-disc ml-12 mt-4">
        <li>A terrain editor</li>
        <li>Path navigation for characters movement</li>
        <li>Basic RTS fighting mechanics: melee and ranged attacks</li>
      </ul>
      <p>
        The codebase is entirely written in <b>C++</b> and the libraries being
        used are: <b>OpenGL, glm, glew, glad, assimp, FMOD and imgui</b>
      </p>

      <p>
        I decided to start posting updates on my progress on this page not only
        as a portfolio piece, but also to inspire other passionate developers to
        try the same, it's as rewarding as it is challenging.
      </p>
      <div className="flex flex-col content-center items-center pt-8 border-t-2 border-cyan-700">
        <h3 className="text-3xl uppe bg-gradient-to-br from-teal-500 to-fuchsia-500 bg-clip-text text-transparent">
          June 2024: First steps
        </h3>
        <p>
          Finished the core project setup, I currently have a barebone versions
          of the following systems:
          <ul class="list-disc ml-12 mt-4">
            <li>
              Model Loading (<i>using assimp</i>), basic materials and skybox
            </li>
            <li>Unity inspired GameObject - Component model</li>
            <li>Game loop that handles model rendering / input reading</li>
            <li>Freelook camera and RTS style camera</li>
            <li>Linetracing for mouse click - movement</li>
            <li>
              Editor vs Engine(game) separation, so that editor code isn't
              compiled into the game
            </li>
          </ul>
        </p>
        <video class="w-2/3 max-w-screen-sm p-8 rounded-md" autoPlay muted loop>
          <source src={video} />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default GameEngine;
