const Contact = () => {
  return (
    <div
      id="contact"
      className="p-10 container mx-auto flex flex-1 flex-col gap-4"
    >
      <h1 className="text-2xl md:text-7xl text-center">CONTACT</h1>
      <div className="flex justify-center items-center flex-col gap-4">
        <p>SEND ME AN EMAIL AT</p>
        <div>
          <a
            href="mailto:nevio.valsa@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="flex bg-gradient-to-br from-teal-500 to-fuchsia-500 p-1"
          >
            <span className="bg-neutral-100 px-6 py-2">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-fuchsia-500">
                NEVIO.VALSA@GMAIL.COM
              </span>
            </span>
          </a>
        </div>
        <p>VISIT ME</p>
        <div className="flex gap-8">
          <a
            href="https://github.com/valsan"
            target="_blank"
            rel="noreferrer"
            className="hover:scale-110 hover:rotate-2 hover:opacity-100 transition-all bg-clip-padding p-2 opacity-90"
          >
            <i className="fa-brands fa-github text-6xl bg-gradient-to-bl from-teal-500 to-fuchsia-500 bg-clip-text text-transparent"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/nevio-valsangiacomo-04193b18a/"
            target="_blank"
            rel="noreferrer"
            className="hover:scale-110 hover:rotate-2 hover:opacity-100 transition-all bg-clip-padding p-2 opacity-90"
          >
            <i className="fa-brands fa-linkedin text-6xl bg-gradient-to-br from-teal-500 to-fuchsia-500 bg-clip-text text-transparent"></i>
          </a>
        </div>
        <p>OR</p>
        <div>
          <a
            href="./resume/nevio-valsangiacomo-resume.pdf"
            download
            className="flex bg-gradient-to-br from-teal-500 to-fuchsia-500 p-1"
          >
            <span className="bg-neutral-100 px-6 py-2">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-fuchsia-500">
                DOWNLOAD RESUME
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
