const Navbar = () => {
  return (
    <div className="absolute z-20 w-full shadow-md border-b border-teal-500">
      <span className="relative container z-20 mx-auto p-2 md:p-4 flex flex-row justify-between items-center">
        <div className="hidden md:flex flex-row flex-1 justify-center items-center gap-8">
          <a
            href="#game-engine"
            className="text-2xl border-b-4 border-transparent hover:border-b-4 hover:border-teal-100 hover:border-opacity-50 transition-all"
          >
            MAKING A GAME
          </a>
          <a
            href="#projects"
            className="text-2xl border-b-4 border-transparent hover:border-b-4 hover:border-teal-100 hover:border-opacity-50 transition-all"
          >
            OLDER PROJECTS
          </a>
          <a
            href="#contact"
            className="text-2xl border-b-4 border-transparent hover:border-b-4 hover:border-teal-100 hover:border-opacity-50 transition-all"
          >
            CONTACT
          </a>
          <a href="https://github.com/valsan" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-github text-2xl"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/nevio-valsangiacomo-04193b18a/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin text-2xl"></i>
          </a>
        </div>
      </span>
    </div>
  );
};

export default Navbar;
