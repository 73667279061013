import Home from './components/Home';
import Projects from './components/Projects';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import GameEngine from './components/GameEngine';

const App = () => {
  return (
    <div className="overflow-x-hidden">
      <header>
        <Navbar />
      </header>
      <div className="h-screen flex flex-1">
        <Home />
      </div>
      <div className="-z-50 bg-slate-50">
        <div className="-z-40 shadow-2xl h-4 w-full"></div>
        <GameEngine />
        <Projects />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

const Footer = () => {
  return (
    <div className="py-4 bg-fuchsia-900 flex justify-center content-center text-white text-sm">
      © {new Date().getFullYear()} - Nevio Valsangiacomo
    </div>
  );
};

export default App;
